import getApplicationEndpoint, {
  ApiGet_ApplicationType,
} from "api/endpoints/get/application/getApplicationEndpoint";
import client from "utilities/apiClient";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import Input from "components/mui/Inputs/Input";
import useLoaderData from "hooks/useLoaderData";
import useGetWithLoader from "hooks/useGetWithLoader";
import stylex from "utilities/stylex";
import { useCallback, useMemo, useState } from "react";
import PageHeader from "components/PageHeader";
import EmptySearchState from "components/EmptySearchState";
import { useUserContextV2 } from "context/UserContextV2";
import PowerSearch from "components/PowerSearch";
import {
  PowerSearchOptionsMap,
  PowerSearchFilterData,
} from "components/PowerSearch/PowerSearchTypes";
import powerSearchFilterItems from "components/PowerSearch/powerSearchFilterItems";
import { ApiPartner } from "@presta-technologies-inc/presta-types";
import getPartnerOrgsByLenderIdEndpoint from "api/endpoints/get/partner/getPartnerOrgsByLenderIdEndpoint";
import ViewerContext from "context/UserContextV2/ViewerContext";
import ApplicationLenderTable from "./ApplicationLenderTable";
import ApplicationsExportButtonMenu from "../ApplicationsExportPage/ApplicationsExportButtonMenu";

const styles = stylex({
  input: {
    margin: (theme) => theme.spacing(3, 2),
    width: "230px",
  },
});

type ApplicationPageLoaderData = {
  applicationList: Array<ApiGet_ApplicationType>;
  partnerList: Array<ApiPartner>;
};

export function getApplicationLenderPageLoader(vc: ViewerContext) {
  return async () => {
    const lenderId = vc.getLenderId();
    if (lenderId == null) {
      throw new Error(`lenderId must be defined for ApplicationLenderPage`);
    }
    const { data: applicationData } = await client<
      ApiPayload<Array<ApiGet_ApplicationType>>
    >(getApplicationEndpoint());

    const { data: partnerData } = await client<ApiPayload<Array<ApiPartner>>>(
      getPartnerOrgsByLenderIdEndpoint(lenderId)
    );

    return { applicationList: applicationData, partnerList: partnerData };
  };
}

export default function ApplicationLenderPageContent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [isShowAssignedToMe, setIsShowAssignedToMe] = useState(false);

  const [powerSearchFilterData, setPowerSearchFilterData] =
    useState<PowerSearchFilterData | null>(null);

  const { viewerContext } = useUserContextV2();
  const user = viewerContext.getUserX();

  const { applicationList: applicationListPageData, partnerList } =
    useLoaderData<ApplicationPageLoaderData>();

  const hasPartners = (partnerList.length ?? 0) > 0;

  const { data: applicationList, refetch: refetchApplicationList } =
    useGetWithLoader<ApiGet_ApplicationType[]>(
      getApplicationEndpoint(),
      applicationListPageData
    );

  const getSearchedItems = useCallback(
    function getSearchedItems(
      formattedItems: Array<{
        id: ID;
        leadUserName: string;
        loanProduct: string;
        menu: null;
        orgName: string;
        partnerOrg?: string;
        startedAt: Date;
        stepName: string;
        userCode: string;
      }>
    ) {
      let out = formattedItems;
      out = searchTerm
        ? out.filter((item) =>
            item.orgName.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : out;

      out = isShowAssignedToMe
        ? out.filter((item) => item.userCode === user.code)
        : out;

      return out;
    },
    [isShowAssignedToMe, searchTerm, user.code]
  );

  const items = useMemo(() => {
    const formattedItems = applicationList.map((item) => {
      const { leadUser, stepName: currentStepName } = item.currentStep;

      return {
        dataTestId: "application-page-table-row",
        id: item.id,
        leadUserName:
          leadUser == null
            ? "Unassigned"
            : `${leadUser.firstName} ${leadUser.lastName}`,
        loanProduct: item.loanProductName,
        menu: null,
        orgName: item.borrowerOrganizationName,
        partnerOrg: item.partnerOrgs,
        startedAt: new Date(item.startedAt),
        stepName: currentStepName,
        userCode: leadUser?.code ?? "",
      };
    });

    return getSearchedItems(formattedItems);
  }, [applicationList, getSearchedItems]);

  const powerSearchOptionsMap: PowerSearchOptionsMap = useMemo(() => {
    const out: PowerSearchOptionsMap = {
      leadUserName: {
        label: "Lead",
        options: new Set<string>(),
      },
      loanProduct: {
        label: "Loan Product",
        options: new Set<string>(),
      },
      ...(hasPartners
        ? {
            partnerOrg: {
              label: "Partner Organization",
              options: new Set<string>(),
            },
          }
        : {}),

      stepName: {
        label: "Step",
        options: new Set<string>(),
      },
    };

    const keys = Object.keys(out) as Array<keyof (typeof items)[number]>;

    for (const item of items) {
      for (const key of keys) {
        if (!Boolean(item[key])) {
          break;
        }
        const value = item[key];
        out[key].options.add(String(value));
      }
    }

    return out;
  }, [hasPartners, items]);

  const filteredItems = powerSearchFilterItems(powerSearchFilterData, items);

  const itemsLength = items.length;

  function renderTable() {
    if (searchTerm.length > 0 && items.length === 0) {
      return <EmptySearchState />;
    }

    return (
      <ApplicationLenderTable
        hasPartners={hasPartners}
        refetchApplicationList={refetchApplicationList}
        tableRows={filteredItems}
      />
    );
  }

  const handleFilter = useCallback((filterData: PowerSearchFilterData) => {
    setPowerSearchFilterData(filterData);
  }, []);

  return (
    <>
      <PageHeader
        secondaryTitle={`(${itemsLength} application${
          itemsLength === 1 ? "" : "s"
        })`}
        title="Applications"
      >
        <ApplicationsExportButtonMenu />
      </PageHeader>
      <Stack alignItems="center" direction="row">
        <Input
          dataTestId="application-page-search-input"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by business..."
          sx={styles.input}
        />
        <FormGroup sx={{ alignItems: "center", flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox data-testid="application-table-assigned-filter" />
            }
            label="Assigned to me"
            onChange={(_, checked: boolean) => setIsShowAssignedToMe(checked)}
          />
        </FormGroup>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{
          mx: 2,
        }}
      >
        <Typography>Filter By:</Typography>
        <PowerSearch
          handleFilter={handleFilter}
          optionsMap={powerSearchOptionsMap}
          saveToQueryParams
          sx={{ flexGrow: 1 }}
        />
      </Stack>
      {renderTable()}
    </>
  );
}
